<template>
    <div>
        <rxNavBar title="电子合同"></rxNavBar>
        <iframe style="height: 700px; width: 10rem; display: block"
                :src="electronicContractUrl" frameborder=0 name="showHere" scrolling=auto
        ></iframe>
    </div>

</template>

<script>
    import rxNavBar from "../../components/rongxun/rx-navBar/navBar";

    export default {
        components: {
            rxNavBar,
        },
        name: "electronicContract",
        data() {
            return {
                electronicContractUrl: '',
            }
        },
        mounted() {
            this.electronicContractUrl = this.$route.query.electronicContractUrl
        }
    }

</script>

<style scoped>

</style>